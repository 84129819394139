<template>
    <!--
    Страница списка голосований
    -->
    <el-container direction="vertical">
        <dom-table
            :items="tableData"
            :default-sort="{prop: 'start_dttm', order: 'ascending'}"
            border
            @row-click="goToVote"
        >
            <template v-if="isMobile">
                <el-table-column prop="title" label="Тема голосования" sortable width="320">
                    <template v-slot:header="scope">
                  <span class="vote-title">
                    <i class="vote-icon fas el-icon-fa-fw el-icon-fa-circle"/>
                    Тема голосования
                  </span>
                    </template>

                    <template v-slot="scope">
                  <span class="vote-title">
                    <i class="vote-icon" :class="`vote-status-icon-${scope.row.voting_status.cd}`"/>
                    {{ scope.row.title }}
                  </span>
                    </template>
                </el-table-column>

                <el-table-column prop="datefrom" label="Дата" sortable width="180">
                    <template slot-scope="scope">
                  <span style="width: fit-content; white-space: nowrap">
                    {{ scope.row.start_dttm | ru_date_short }} &mdash; {{ scope.row.end_dttm | ru_date_short }}
                  </span>
                    </template>
                </el-table-column>

                <el-table-column sortable label="Адрес">
                    <template v-slot="scope">
                        <span class="vote-title">{{ scope.row.property.address }}</span>
                    </template>
                </el-table-column>

                <el-table-column width="40px">
                    <template v-slot:header="scope">
                        <i class="fas el-icon-fa-fw el-icon-fa-eye vote-view-header"/>
                    </template>
                    <template v-slot="scope">
                        <router-link :to="{name: 'vote', params: {id: scope.row.votingId}}">
                            <i class="fas el-icon-fa-fw el-icon-fa-eye vote-view-link"/>
                        </router-link>
                    </template>
                </el-table-column>
            </template>


            <!--            карточки для мобильной версии-->
            <template v-slot:mobile="{computedTableData}" v-if="!isMobile">
                <el-card class="box-card" v-for="(vote, idx) in computedTableData" :key="idx">
                        <div slot="header" class="card-header" @click="goToVote(vote)">
                            <div><i class="vote-icon" :class="`vote-status-icon-${vote.voting_status.cd}`"/>
                                <span>{{ vote.title }}</span>
                            </div>
                            <router-link :to="{name: 'vote', params: {id: vote.votingId}}">
                                <i class="fas el-icon-fa-fw el-icon-fa-eye vote-view-link"/>
                            </router-link>
                        </div>
                        <div @click="goToVote(vote)">
                            <span style="font-weight: bold;">Адрес:</span>
                            <span> {{ vote.property.address }}</span>
                        </div>
                        <div class="card-row" @click="goToVote(vote)">
                            <span style=" font-weight: bold;">Дата проведения:</span>
                            <span> {{ vote.start_dttm | ru_date_short }} &mdash; {{ vote.end_dttm | ru_date_short }}</span>
                        </div>
                </el-card>
            </template>
        </dom-table>

    </el-container>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from 'vuex-map-fields';
import DomTable from "@/views/elements/DomTable";
import VueScreenSize from "vue-screen-size";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],
    components: {DomTable},
    data() {
        return {
            perPage: 5,
        }
    },
    created() {
        this.update();
    },

    computed: {
        ...mapGetters("voting", {tableData: "all"}),
        ...mapFields("voting", ['currentPage',]),
        isMobile() {
            return this.$vssWidth > 1000
        },
    },

    methods: {
        ...mapActions("voting", ["update"]),

        goToVote(row) {
            this.$router.push({name: 'vote', params: {id: row.votingId}});
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.box-card {
    margin-top: 10px;

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
    }

    .card-row {
        margin-top: 10px;
    }
}

.el-pagination {
    margin-top: 15px;

    ::v-deep .el-pager li {
        height: 35px;
        width: 35px;
        line-height: 35px;
        font-size: 12px;
        border: 1px solid $dom-gray;
        border-radius: 0;
    }

    ::v-deep .el-pager li.active {
        height: 35px;
        width: 35px;
        line-height: 36px;
        font-size: 12px;
        border: 0;
        border-radius: 0;
    }

    ::v-deep .btn-next {
        height: 35px;
        width: 35px;
        line-height: 35px;
    }

    ::v-deep .btn-prev {
        height: 35px;
        width: 35px;
        line-height: 35px;
    }
}

.vote-title {
    word-break: break-word;
}

.vote-view-header {
    color: $dom-gray;
}

.vote-view-link {
    color: $dom-blue;
}

</style>
